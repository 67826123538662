import styled from '@emotion/styled';
import Color from '../constants/Color';
import IconType from '../constants/IconType';

export enum IconSize {
  XXL = 72,
  XL = 42,
  L = 28,
  M = 18,
  S = 14,
}

export type IconProps = {
  icon: IconType;
  size?: IconSize;
  color?: Color;
  className?: string;
  dataQA?: string;
};

type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

const StyledIcon = styled.i<Without<IconProps, 'icon'>>`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  font-size: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  color: ${(props) => props.color};
  &.hoverBgColor:hover {
    svg {
      fill: ${Color.PRIMARY60};
    }
  }
`;

export function BCIcon({
  icon,
  size = IconSize.M,
  color = Color.PRIMARY,
  className,
  dataQA,
}: IconProps) {
  return (
    <StyledIcon
      data-qa={dataQA}
      className={`${icon} ${className}`}
      size={size}
      color={color}
    ></StyledIcon>
  );
}

export interface BCIconsProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
}
export const BCIcons = {
  WhatsApp: ({ width = 11, height = 11, fill = 'black' }: BCIconsProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.53976 5.40088C7.21376 5.13765 6.72345 5.17382 6.44746 5.48572L6.21952 5.74272C6.21108 5.75083 6.19939 5.75395 6.1877 5.75083C5.88702 5.65352 5.32658 5.3803 4.95773 4.64236C4.95448 4.63425 4.95578 4.62489 4.96292 4.61678L5.24736 4.30051C5.5214 3.99547 5.49154 3.52764 5.17917 3.25941L4.54405 2.70861C4.38949 2.57449 4.18689 2.50588 3.98037 2.51835C3.77256 2.52958 3.58164 2.61878 3.44201 2.76911L3.05951 3.18456C2.88807 3.36984 2.78547 3.60937 2.76663 3.85701C2.72831 4.38722 2.8017 5.73959 4.29274 6.86366C4.41937 6.9722 5.49479 7.86172 6.70134 7.86172C6.93772 7.86172 7.17995 7.82803 7.41958 7.74694C7.61246 7.68144 7.78713 7.56791 7.92156 7.4182L8.29562 7.00151C8.43199 6.84993 8.49824 6.65468 8.482 6.45506C8.46512 6.25545 8.369 6.07331 8.20925 5.94418L7.53976 5.40088ZM7.79237 6.58609L7.41832 7.00278C7.35987 7.06828 7.28389 7.11756 7.20076 7.14625C6.0617 7.52926 4.74345 6.39584 4.73049 6.38586L4.71165 6.36901C3.5083 5.46513 3.39141 4.43644 3.42973 3.90196C3.43817 3.79342 3.48298 3.68987 3.55767 3.61003L3.94017 3.19458C3.96679 3.16588 3.99991 3.15964 4.0181 3.15777H4.02459C4.04277 3.15777 4.07265 3.16276 4.09927 3.18522L4.73439 3.73602C4.7792 3.77408 4.78245 3.84145 4.74413 3.88636L4.4597 4.20263C4.28047 4.40411 4.2389 4.68668 4.35839 4.9231C4.83246 5.87189 5.57537 6.22932 5.97606 6.36031C6.24557 6.44827 6.54104 6.36967 6.72613 6.16257L6.95407 5.90556C6.99368 5.86065 7.06382 5.85629 7.11058 5.89309L7.78075 6.43766C7.81258 6.46324 7.81907 6.49505 7.82037 6.51127C7.82037 6.52375 7.819 6.55552 7.79237 6.58609Z"
        fill={fill}
      />
      <path
        d="M5.59078 0.000175746C4.14586 0.000175746 2.78801 0.541622 1.76509 1.52222C-0.0272313 3.24384 -0.329969 5.93302 1.01892 7.9753L0 11L3.00543 9.7624C5.10364 10.8609 7.7169 10.5047 9.41761 8.87163C11.5275 6.845 11.5275 3.54947 9.41761 1.52268C8.39545 0.540217 7.03694 0 5.59058 0L5.59078 0.000175746ZM8.9475 8.41953C7.42139 9.88544 5.05364 10.1793 3.19498 9.1319L3.04887 9.04831L1.08382 9.85798L1.75075 7.87814L1.65919 7.74902C0.387659 5.94627 0.629882 3.51722 2.23587 1.97461C3.13205 1.11379 4.32378 0.639699 5.59075 0.639699C6.85774 0.639699 8.04939 1.11379 8.94563 1.97461C10.7965 3.74989 10.7964 6.64234 8.9475 8.41953Z"
        fill={fill}
      />
    </svg>
  ),
  Facebook: ({ width = 11, height = 11, fill = 'black' }: BCIconsProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.46047 2.61905H7.69103C7.86048 2.61905 7.99867 2.50139 7.99867 2.35714V0.261905C7.99867 0.117648 7.86048 0 7.69103 0H5.84519C3.97949 0 2.46113 1.29264 2.46113 2.88095V4.21143H0.307641C0.138192 4.21143 0 4.32908 0 4.47333V6.54762C0 6.69188 0.138199 6.80952 0.307641 6.80952H2.24717V10.7381C2.24717 10.8824 2.38537 11 2.55481 11H5.12346C5.29291 11 5.4311 10.8823 5.4311 10.7381V6.80952H7.04929C7.1893 6.80952 7.31247 6.72972 7.34792 6.6131L7.98965 4.51786C8.01428 4.43908 7.99445 4.3557 7.93557 4.29124C7.87728 4.2273 7.78776 4.18894 7.69282 4.18894H5.53933V3.40322C5.53933 2.96995 5.95333 2.61751 6.46226 2.61751L6.46047 2.61905ZM5.2299 4.71429H7.29264L6.81135 6.28571H5.1224C4.95295 6.28571 4.81476 6.40337 4.81476 6.54762V10.4762H2.86139V6.54762C2.86139 6.40336 2.72319 6.28571 2.55375 6.28571H0.614221V4.73524H2.76771C2.93716 4.73524 3.07535 4.61759 3.07535 4.47333V2.88095C3.07535 1.58166 4.31673 0.523809 5.84412 0.523809H7.38233V2.09524H6.45941C5.61159 2.09524 4.9212 2.68299 4.9212 3.40476V4.45238C4.9212 4.59664 5.0594 4.71429 5.22884 4.71429H5.2299Z"
        fill={fill}
      />
    </svg>
  ),
};
