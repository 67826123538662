import React, { PropsWithChildren } from 'react';
import {
  FlexAlignItems,
  BCFlexBox,
  FlexDirection,
  FlexJustifyContent,
} from '../flexBox';

export type HorizontalLayoutProps = {
  distance?: string;
  alignItems?: FlexAlignItems;
  justifyContent?: FlexJustifyContent;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
  spaceBetween?: boolean;
  overflow?: string;
  width?: string;
  height?: string;
  dataTestid?: string;
  minWidth?: string;
  className?: string;
  rtl?: boolean;
};

/**
 * The Horizontal Layout component is a simple way to align multiple elements horizontally.
 *
 * By default the contained controls are not wrapped.
 * If you want more sophisticated layout options, consider GridLayout or FlexBox components.
 */
export const BCHorizontalLayout = ({
  distance,
  alignItems,
  justifyContent,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  spaceBetween,
  overflow,
  children,
  width,
  height,
  dataTestid,
  minWidth,
  className,
  rtl,
}: PropsWithChildren<HorizontalLayoutProps>) => (
  <BCFlexBox
    rtl={rtl}
    direction={FlexDirection.ROW}
    alignItems={alignItems}
    justifyContent={
      justifyContent ??
      (spaceBetween
        ? FlexJustifyContent.SPACE_BETWEEN
        : FlexJustifyContent.NORMAL)
    }
    distance={distance}
    marginTop={marginTop}
    marginRight={marginRight}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    overflow={overflow}
    width={width}
    height={height}
    dataTestid={dataTestid}
    minWidth={minWidth}
    className={className}
  >
    {children}
  </BCFlexBox>
);
