export enum TextSize {
    XXS = 10,
    XS = 12,
    S = 14,
    M = 18,
    L = 22,
    XL = 26,
    XXL = 32,
}

export default TextSize;
