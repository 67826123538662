import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import Color from '../constants/Color';

const animation = keyframes`
    0% {
        background-position: -200px 0;
    }
    50% {
        background-position: calc(380px + 100%) 0;
    }
    100% {
        background-position: -200px 0;
    }
`;

const SkeletonWrapper = styled.div<{
    width: string;
    height: string;
    borderRadius: string;
    backgroundColor: string;
    opacity: number;
    animate?: boolean;
    lightBackground?: string;
    lightAnimate?: string;
    className?: string;
}>`
    ${({ animate, lightBackground, lightAnimate }) =>
        animate
            ? css`
                  animation-duration: 10s;
                  animation-fill-mode: both;
                  animation-iteration-count: infinite;
                  animation-name: ${animation};
                  animation-timing-function: linear;
                  background-image: linear-gradient(
                      to right,
                      ${lightBackground} 8%,
                      ${lightAnimate} 18%,
                      ${lightBackground} 33%
                  );
              `
            : undefined}
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: ${({ borderRadius }) => borderRadius};
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    opacity: ${({ opacity }) => opacity};
    max-width: 100%;
`;

export interface SkeletonProps {
    width?: number | string;
    height?: number | string;
    borderRadius?: number | string;
    backgroundColor?: string;
    opacity?: number;
    animate?: boolean;
    lightBackground?: string;
    lightAnimate?: string;
    className?: string;
}

export function BCSkeleton({
    width = '120px',
    height = '20px',
    borderRadius = '5px',
    backgroundColor = Color.PRIMARY05,
    opacity = 1,
    animate = true,
    lightBackground = Color.PRIMARY10,
    lightAnimate = Color.PRIMARY20,
    className,
}: SkeletonProps) {
    return (
        <SkeletonWrapper
            width={typeof width === 'string' ? width : `${width}px`}
            height={typeof height === 'string' ? height : `${height}px`}
            borderRadius={typeof borderRadius === 'string' ? borderRadius : `${borderRadius}px`}
            backgroundColor={backgroundColor}
            opacity={opacity}
            animate={animate}
            lightBackground={lightBackground}
            lightAnimate={lightAnimate}
            className={className}
        />
    );
}
