import React, {
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import styled, { css } from 'styled-components';
import { BCText } from '../../text/bctext';
import {
  DropdownListItem,
  SizeValues as DropdownListItemSizeValues,
} from './DropdownListItem';
import { BCIcon } from '../../icon/bcicon';
import IconType from '../../constants/IconType';
import { BCAvatar } from '../../avatar';
import Color from '../../constants/Color';
import TextSize from '../../constants/TextSize';
import TextWeight from '../../constants/TextWeight';

import { BCCheckbox } from '../../checkbox/bccheckbox';
import { BCVerticalLayout } from '../../layout';
import {
  DropdownListItemContent,
  DropdownListItemType,
  DropdownListItemValue,
  DropdownListSelectionMode,
  FooterItemsProps,
  HeaderItemsProps,
} from '../types';
import { BCTooltip, TooltipPlacement } from '../../tooltip';

import { BCScrollArea, ScrollAreaSize } from '../../scrollArea';
import Scrollbars from 'react-custom-scrollbars';
import Key from '../../constants/Key';
import WhiteSpace from '../../constants/WhiteSpace';
import BoxShadow from '../../constants/BoxShadow';
import { BCSeparator } from '../../seperator';
import { DropdownListWidth, DropdownSize } from '../../constants';

const SizeValues = {
  [DropdownSize.S]: {
    padding: 8,
  },
  [DropdownSize.M]: {
    padding: 8,
  },
  [DropdownSize.L]: {
    padding: 8,
  },
  [DropdownSize.XL]: {
    padding: 8,
  },
};

const SEPARATOR_TOP_PADDING = 4;
const SEPARATOR_BOTTOM_PADDING = 8;

type DropdownContainerProps = {
  width?: DropdownListWidth;
  fixed?: boolean;
  top?: number;
  left?: number;
  up?: boolean;
};
const DropdownContainerWrapper = styled.div<DropdownContainerProps>`
  margin: 4px 0;
  position: absolute;
  z-index: 100;
  width: ${({ width }) => (width ? `${width}px` : '100%')};

  ${({ fixed, top, left, up }) =>
    fixed
      ? css`
          position: fixed;
          left: 0;
          top: 0;
          transform: translate(${left}px, ${top}px);
        `
      : css`
          top: ${top ? `${top}px` : ''};
          bottom: ${up ? '35px' : ''};
        `}
`;
const DropdownContainer = styled.div`
  background: ${Color.CONTROLS_COLOR};
  border: 1px solid ${Color.LIGHT_COLOR};
  border-radius: 8px;
  box-shadow: ${BoxShadow.M};
  width: 100%;
`;

type ListContainerProps = {
  size: DropdownSize;
};
const ListContainer = styled.div<
  ListContainerProps & { height: number; minHeight: number | undefined }
>`
  padding: ${({ size }) => SizeValues[size].padding}px;
  height: ${({ height }) => height}px;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '')};
`;

const Title = styled.div`
  display: flex;
  padding: 12px;
  border-bottom: 1px solid ${Color.PRIMARY10};
  &.withTooltip {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const FooterContent = styled.div`
  margin: 8px;
`;

const FooterContainer = styled.div<ListContainerProps>`
  padding: ${({ size }) => SizeValues[size].padding};
`;
const HeaderContainer = styled.div<ListContainerProps>`
  padding: ${({ size }) => SizeValues[size].padding}px;
`;

const FooterWrapper = styled.div`
  :not(:last-child) {
    margin-bottom: 4px;
  }
`;

const TooltipWrapper = styled.span`
  display: flex;
`;

function getItemContent({
  selectionMode,
  selected,
  item,
  itemDisabledValue,
  value,
  maxWidth,
  hideBackgroundColorOnSelected,
}: {
  selectionMode: DropdownListSelectionMode;
  selected: boolean;
  item: DropdownListItemContent;
  itemDisabledValue?: DropdownListItemValue;
  value?: DropdownListItemValue;
  maxWidth?: string;
  hideBackgroundColorOnSelected?: boolean;
}): ReactElement[] {
  const itemChildren: ReactElement<any, any>[] = [];

  switch (selectionMode) {
    case DropdownListSelectionMode.CHECKBOX:
      itemChildren.push(
        <BCCheckbox key="checkbox" checked={selected} label="" />
      );
      break;
    case DropdownListSelectionMode.RADIO:
      // itemChildren.push(<Radio key="radio" />);
      break;
    case DropdownListSelectionMode.NONE:
    default:
      break;
  }

  if (item?.icon) {
    if (typeof item.icon === 'string') {
      itemChildren.push(
        <BCIcon key="icon" icon={item.icon} color={Color.PRIMARY40} />
      );
    } else {
      itemChildren.push(item.icon);
    }
  }

  if (item?.avatar) {
    itemChildren.push(<BCAvatar key="avatar" {...item.avatar} />);
  }

  if (item?.text) {
    const hasValue = itemDisabledValue && value && itemDisabledValue === value;
    let finalTextChild = (
      <BCText
        key="text"
        size={TextSize.XS}
        weight={TextWeight.MEDIUM}
        color={hasValue ? Color.PRIMARY40 : Color.PRIMARY}
        maxWidth={maxWidth}
        ellipsis
        whiteSpace={WhiteSpace.NOWRAP}
      >
        {item.text}
      </BCText>
    );

    if (item?.description) {
      finalTextChild = (
        <BCVerticalLayout key="textWithDescription" distance="2px">
          {finalTextChild}
          <BCText
            size={TextSize.XS}
            weight={TextWeight.REGULAR}
            color={Color.PRIMARY60}
            ellipsis
            whiteSpace={WhiteSpace.NOWRAP}
          >
            {item.description}
          </BCText>
        </BCVerticalLayout>
      );
    }

    itemChildren.push(finalTextChild);
  }

  if (item?.component) {
    itemChildren.push(item.component);
  }

  return itemChildren;
}

export type DropdownListProps = DropdownContainerProps &
  ListContainerProps & {
    items: DropdownListItemType[];
    maxVisibleItems?: number;
    selectedValue?: DropdownListItemValue;
    title?: string;
    disableValue?: DropdownListItemValue;
    selectionMode?: DropdownListSelectionMode;
    onItemClick?: (value: DropdownListItemValue, fromEnter: boolean) => void;
    onItemMouseDown?: (
      value: DropdownListItemValue,
      index?: number,
      e?: React.MouseEvent
    ) => void;
    selectedState?: boolean;
    headerItems?: HeaderItemsProps[] | null;
    footerItems?: FooterItemsProps[] | null | undefined;
    hideDropdown?: () => void;
    tooltipValue?: string;
    titleTooltip?: string;
    itemAsLink?: boolean;
    setSelected?: (value?: DropdownListItemValue) => void;
    dataTestid?: string;
    minHeight?: number;
    parentTop?: number;
    className?: string;
    maxWidth?: string;
    up?: boolean;
    id?: string;
    hideBackgroundColorOnSelected?: boolean;
    contextMenuY?: number;
  };

/**
 * This component will not be exported
 */
export const DropdownList = forwardRef<HTMLDivElement, DropdownListProps>(
  (props: DropdownListProps, ref) => {
    const {
      items,
      selectedValue,
      disableValue = '',
      size,
      title,
      width,
      fixed = false,
      top = 0,
      left = 0,
      parentTop,
      onItemClick,
      onItemMouseDown,
      selectionMode = DropdownListSelectionMode.NONE,
      selectedState = true,
      headerItems,
      footerItems,
      hideDropdown,
      titleTooltip,
      itemAsLink = false,
      dataTestid,
      setSelected,
      minHeight,
      className,
      maxWidth,
      up,
      id = '',
      hideBackgroundColorOnSelected = false,
      contextMenuY,
    } = props;
    const scrollRef = useRef<Scrollbars>(null);

    const maxVisibleItems = props.maxVisibleItems || items.length;

    const titleContent = title ? (
      <Title className={titleTooltip ? 'withTooltip' : ''}>
        <BCText
          color={Color.PRIMARY40}
          size={TextSize.XXS}
          weight={TextWeight.BOLD}
          uppercase
          ellipsis
          whiteSpace={WhiteSpace.NOWRAP}
        >
          {title}
        </BCText>
        {titleTooltip && (
          <BCTooltip
            title={titleTooltip}
            placement={TooltipPlacement.RIGHT}
            maxWidth={260}
          >
            <TooltipWrapper>
              <BCIcon key="icon" icon={IconType.info} color={Color.PRIMARY40} />
            </TooltipWrapper>
          </BCTooltip>
        )}
      </Title>
    ) : null;

    const headerContent = headerItems?.length ? (
      <>
        <HeaderContainer size={size}>
          {headerItems.map(({ content, onItemClick }, index) => (
            <DropdownListItem
              key={`header${index}`}
              value={index}
              size={size}
              itemAsLink={itemAsLink}
              onClick={() => {
                hideDropdown && hideDropdown();
                onItemClick && onItemClick();
              }}
            >
              {getItemContent({
                selectionMode,
                selected: false,
                item: content,
              })}
            </DropdownListItem>
          ))}
        </HeaderContainer>
        <BCSeparator color={Color.PRIMARY10} padding="0" />
      </>
    ) : null;

    const footerContent = footerItems?.length ? (
      <FooterContainer size={size}>
        <BCSeparator color={Color.PRIMARY10} padding="0" />
        <FooterContent>
          {footerItems.map(({ content, onItemClick }, index) => {
            return (
              <FooterWrapper key={index}>
                <DropdownListItem
                  key={index}
                  value={index}
                  size={size}
                  itemAsLink={itemAsLink}
                  onClick={() => {
                    hideDropdown && hideDropdown();
                    onItemClick && onItemClick();
                  }}
                >
                  {getItemContent({
                    selectionMode,
                    selected: false,
                    item: content,
                  })}
                </DropdownListItem>
              </FooterWrapper>
            );
          })}
        </FooterContent>
      </FooterContainer>
    ) : null;

    const listContent = items.map(
      (
        {
          value,
          selected = false,
          content,
          isSeparator = false,
          tooltipText = '',
          tooltipTextPlacement = TooltipPlacement.LEFT,
          disabled = false,
          showTooltipOnDisabled = false,
        },
        index
      ) => {
        if (isSeparator) {
          return (
            <BCSeparator
              key={`${value}_${index}`}
              color={Color.PRIMARY10}
              padding={`${SEPARATOR_TOP_PADDING}px 0 ${SEPARATOR_BOTTOM_PADDING}px 0`}
            />
          );
        }

        if (typeof value === 'undefined' || !content) {
          return null;
        }

        /** If we have a disableValue for all dropdown list and we want to disabled all items with that value */
        let itemDisabledValue = disableValue;
        /** If item has a disabled prop then we take the value to disable it too */
        if (disabled) {
          itemDisabledValue = value;
        }

        return (
          <DropdownListItem
            key={`${value}_${index}`}
            value={value}
            size={size}
            onClick={(value) => {
              onItemClick && onItemClick(value, false);
            }}
            onMouseDown={(val, e) =>
              onItemMouseDown && onItemMouseDown(val, index, e)
            }
            disabled={itemDisabledValue === value}
            selected={selectedState && (selected || value === selectedValue)}
            tooltipText={tooltipText}
            itemAsLink={itemAsLink}
            tooltipTextPlacement={tooltipTextPlacement}
            showTooltipOnDisabled={showTooltipOnDisabled}
            hideBackgroundColorOnSelected={hideBackgroundColorOnSelected}
          >
            {getItemContent({
              selectionMode,
              selected,
              item: content,
              itemDisabledValue,
              value,
              maxWidth,
              hideBackgroundColorOnSelected,
            })}
          </DropdownListItem>
        );
      }
    );

    const { height: itemHeight, marginBottom: itemMarginBottom } =
      DropdownListItemSizeValues[size];

    const itemsHeight = items.reduce((acc, val, i) => {
      if (i < maxVisibleItems) {
        if (val.isSeparator) {
          // separator has 1px height
          return (
            acc +
            1 +
            SEPARATOR_TOP_PADDING +
            SEPARATOR_TOP_PADDING / 2 +
            SizeValues[size].padding
          );
        } else {
          return (
            acc +
            itemHeight +
            (i !== items.length - 1 && i !== maxVisibleItems - 1
              ? itemMarginBottom
              : 0)
          );
        }
      }

      return acc;
    }, 0);

    const setScroll = useCallback(
      (newIndex: number) => {
        const indexToHeight = (index: number) => {
          return index * (itemHeight + itemMarginBottom);
        };
        const scrollToHeight = (myHeight: number) => {
          if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollTop(myHeight);
          }
        };
        if (maxVisibleItems) {
          if (newIndex === 0) {
            scrollToHeight(0);
          } else {
            // verificare daca s-a selectat un item cu index
            // mai mare decat maximul de elemente vizibile
            if (newIndex + 1 > maxVisibleItems) {
              scrollToHeight(indexToHeight(newIndex + 1 - maxVisibleItems));
            }
          }
        }
      },
      [itemHeight, itemMarginBottom, maxVisibleItems]
    );

    const arrowsKeysSelection = useCallback(
      (e: KeyboardEvent) => {
        const setItem = (newItem: DropdownListItemType) => {
          if (newItem?.value && setSelected) {
            setSelected(newItem.value);
          }
        };
        switch (e.key) {
          case Key.ArrowUp:
            {
              const findSelectedIndex = items.findIndex(
                (item) => item.value === selectedValue
              );
              const newIndex =
                (findSelectedIndex === 0 || findSelectedIndex === -1
                  ? items.length
                  : findSelectedIndex) - 1;
              const newItem = items[newIndex].isSeparator
                ? items[newIndex - 1]
                : items[newIndex];
              setItem(newItem);
            }
            break;
          case Key.ArrowDown:
            {
              const findSelectedIndex = items.findIndex(
                (item) => item.value === selectedValue
              );
              const newIndex =
                (findSelectedIndex === items.length - 1 ||
                  findSelectedIndex === -1
                  ? -1
                  : findSelectedIndex) + 1;
              const newItem = items[newIndex].isSeparator
                ? items[newIndex + 1]
                : items[newIndex];
              setItem(newItem);
            }
            break;
          case Key.Escape:
            hideDropdown && hideDropdown();
            break;
          case Key.Enter:
            if (onItemClick && selectedValue) {
              onItemClick(selectedValue, true);
            }
            break;
        }
      },
      [setSelected, hideDropdown, onItemClick, selectedValue, items]
    );

    useEffect(() => {
      const findSelectedIndex = items.findIndex(
        (item) => item.value === selectedValue
      );
      setScroll(findSelectedIndex);
      document.addEventListener('keydown', arrowsKeysSelection, true);

      return () => {
        document.removeEventListener('keydown', arrowsKeysSelection, true);
      };
    }, [items, selectedValue, setScroll, arrowsKeysSelection]);

    const height =
      itemsHeight +
      4 * SizeValues[size].padding +
      (Number(contextMenuY) < 0 ? Number(contextMenuY) : 0);
    const upDirection =
      up ||
      (!!parentTop &&
        window.innerHeight - parentTop < height + (title ? 50 : 0));

    return (
      <DropdownContainerWrapper
        id={id}
        width={width}
        top={top}
        left={left}
        up={upDirection}
        ref={ref}
        fixed={fixed}
        className={className}
      >
        <DropdownContainer>
          {titleContent}
          {headerContent}
          <ListContainer
            data-testid={dataTestid}
            size={size}
            height={height}
            minHeight={minHeight}
          >
            <BCScrollArea
              size={ScrollAreaSize.S}
              ref={scrollRef}
              hideHorizontalTrack={true}
            >
              {listContent}
            </BCScrollArea>
          </ListContainer>
          {footerContent}
        </DropdownContainer>
      </DropdownContainerWrapper>
    );
  }
);
