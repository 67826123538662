import React, {
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import { Cross1Icon } from '@radix-ui/react-icons';
import Color from '../constants/Color';
import { BCText } from '../text/bctext';
import TextSize from '../constants/TextSize';
import TextWeight from '../constants/TextWeight';
import { Key } from '../constants/Key';
import { BCTextbox } from '../textbox/bctextbox';
import {
  FlexAlignItems,
  BCFlexBox,
  FlexDirection,
  FlexJustifyContent,
  BCHorizontalLayout,
} from '../layout';
import { BCScrollArea, ScrollAreaSize } from '../scrollArea';
import BoxShadow from '../constants/BoxShadow';
import WhiteSpace from '../constants/WhiteSpace';

const Wrapper = styled.div`
  position: relative;
`;

const InputTagWrapper = styled.div<{ focusBorder: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 8px;
  width: 100%;
`;

const TagsWrapper = styled.div<{ marginLeft: string }>`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
`;

const Tag = styled(BCHorizontalLayout)<{ disabled: boolean }>`
  background-color: ${Color.PRIMARY30};
  border: 1px solid ${Color.PRIMARY};
  padding: 2px 6px;
  border-radius: 5px;
  margin: 0 4px 4px 0;
  height: 28px;
  ${({ disabled }) => {
    const style = disabled
      ? 'opacity: 0.4; pointer-events: none;'
      : 'cursor: pointer;';

    return css`
      ${style}
    `;
  }}
  svg {
    margin-right: 6px;
    margin-top: 2px;
  }
`;

const TagValue = styled(BCText)`
  -ms-word-break: break-all;
  word-break: break-all;
`;

const Search = styled(BCTextbox)`
  display: inline;
  max-width: 150px;
  min-width: 100px;
  width: auto;
  margin: 0 12px 4px 0;

  padding: 0 10px;
  min-width: 100px;
  max-width: 150px;
  height: 28px;
`;

const DropdownWrapper = styled(BCFlexBox)<{
  top: number;
  borderBottom: boolean;
}>`
  background-color: ${Color.CONTROLS_COLOR};
  position: absolute;
  left: 0;
  top: ${({ top }) => `${top}px`};
  width: 100%;
  max-height: 272px;
  padding: 8px;
  border: 1px solid ${Color.PRIMARY10};
  border-bottom: ${({ borderBottom }) =>
    borderBottom
      ? `6px solid ${Color.ACCENT10}`
      : `1px solid ${Color.PRIMARY10}`};
  box-shadow: ${BoxShadow.M};
  border-radius: 8px;
  z-index: 1;
`;

const GroupLabel = styled(BCText)`
  padding-bottom: 4px;
  padding-left: 12px;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  height: 48px;

  &:hover {
    background-color: ${Color.PRIMARY05};
    border-radius: 8px;
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const ItemLabel = styled(BCText)`
  flex: 1 1 0;
  margin-left: 12px;
`;

const ImageWrapper = styled(BCHorizontalLayout)`
  width: 104px;
  height: 36px;
  margin: 0 6px 0 13px;
  display: flex;
  align-items: center;

  div {
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
    max-height: 36px;
    object-fit: contain;
  }
  &.border {
    border: 1px solid ${Color.PRIMARY10};
    border-radius: 8px;
  }
`;

const NoFoundWrapper = styled.div`
  padding: 8px 4px;
`;

export interface ItemDropdown {
  id: string;
  label: string;
  thumb?: string | null;
  groupLabel?: string | null;
}

interface ItemGroups {
  [key: string]: JSX.Element[];
}

export interface SelectedItem {
  id: string;
  disabled: boolean;
}

export type InputTagsProps = {
  items?: ItemDropdown[];
  selectedItems?: SelectedItem[];
  placeholder?: string;
  unselectItem: (id: string) => void;
  selectItem: (id: string) => void;
  isGroupedItems?: boolean;
  noResultsFoundText?: string;
  allowAddingNewTags?: boolean;
};

interface ListDropdownItemProps {
  item: ItemDropdown;
  index: number;
  handleClickItem: (
    event: React.MouseEvent<Element, MouseEvent>,
    id: string
  ) => void;
}

const ListDropdownItem = ({
  item: { id, label, thumb },
  index,
  handleClickItem,
}: ListDropdownItemProps) => (
  <DropdownItem
    data-testid={`${label}ListItem`}
    onClick={(ev) => handleClickItem(ev, id)}
    key={`${index}-${label}`}
  >
    <ItemLabel
      weight={TextWeight.MEDIUM}
      size={TextSize.S}
      ellipsis
      whiteSpace={WhiteSpace.NOWRAP}
    >
      {label}
    </ItemLabel>

    {thumb ? (
      <ImageWrapper
        alignItems={FlexAlignItems.CENTER}
        justifyContent={FlexJustifyContent.CENTER}
        className={thumb ? '' : 'border'}
      >
        <img src={thumb} alt={label} />{' '}
      </ImageWrapper>
    ) : null}
  </DropdownItem>
);

export function BCInputTags({
  items,
  selectedItems,
  placeholder,
  unselectItem,
  selectItem,
  isGroupedItems = false,
  allowAddingNewTags = true,
  noResultsFoundText,
}: InputTagsProps) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [dropdownTop, setDropdownTop] = useState<number>(42);
  const [focusBorder, setFocusBorder] = useState<boolean>(false);
  const prevSelectedItems = useRef<SelectedItem[]>([]);

  const inputTagRef = useRef<HTMLDivElement>(
    null
  ) as MutableRefObject<HTMLDivElement>;
  const refScroll = useRef<Scrollbars>(null);

  const handleClickOutside = useCallback(({ target }: MouseEvent) => {
    if (target && !inputTagRef.current?.contains(target as Node)) {
      setOpenDropdown(false);
    }
  }, []);

  useEffect(() => {
    if (
      refScroll.current &&
      selectedItems &&
      prevSelectedItems.current.length < selectedItems.length
    ) {
      refScroll.current.scrollToBottom();
    }

    if (selectedItems) prevSelectedItems.current = selectedItems;
  }, [selectedItems]);

  useEffect(() => {
    if (openDropdown) {
      document.addEventListener('mousedown', handleClickOutside, true);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [openDropdown, handleClickOutside]);

  const handleClickItem = useCallback(
    (ev: React.MouseEvent<Element, MouseEvent> | KeyboardEvent, id: string) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (searchTerm.length) {
        setSearchTerm('');
      }
      selectItem(id);
    },
    [searchTerm.length, selectItem]
  );

  const handleKeyDownInput = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === Key.Escape) {
        setOpenDropdown(false);
      }

      if (ev.key === Key.Enter) {
        setOpenDropdown(false);
        handleClickItem(ev, searchTerm);
      }
    },
    [searchTerm, handleClickItem]
  );

  const onClickInput = (ev: React.MouseEvent<Element, MouseEvent>) => {
    ev.preventDefault();
    ev.stopPropagation();
    setOpenDropdown(true);
  };

  const onBlurInput = () => {
    setFocusBorder(false);
  };

  const getSelectedItems = useCallback(() => {
    return selectedItems?.reduce(
      (acc: React.ReactNode[], selectedItem: SelectedItem, index: number) => {
        const item = items?.find(
          ({ id: idItem }) => idItem === selectedItem.id
        );

        if (item) {
          acc.push(
            <Tag
              dataTestid={`${item.label}TagComponent`}
              alignItems={FlexAlignItems.CENTER}
              key={`${index}-${item.label}`}
              disabled={selectedItem.disabled}
            >
              <div onClick={() => unselectItem(item.id)}>
                <Cross1Icon width={14} height={14} />
              </div>
              <TagValue size={TextSize.S} weight={TextWeight.REGULAR}>
                {item.label}
              </TagValue>
            </Tag>
          );
        }

        return acc;
      },
      []
    );
  }, [selectedItems, items, unselectItem]);

  useEffect(() => {
    setDropdownTop(inputTagRef.current?.offsetHeight + 4);
  }, [inputTagRef, selectedItems, getSelectedItems]);

  const dropdownItems = useMemo(() => {
    const filteredItems = items?.filter(
      (item) =>
        !selectedItems?.some((selectedItem) => selectedItem.id === item.id) &&
        item.label.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );

    const getDropdownItems = (itemsList?: ItemDropdown[]) =>
      itemsList?.map((x, index) => (
        <ListDropdownItem
          key={x.id}
          item={x}
          index={index}
          handleClickItem={(ev) => handleClickItem(ev, x.id)}
        />
      ));

    if (isGroupedItems) {
      const elements: JSX.Element[] = [];
      const groupedDropdownItems = filteredItems?.reduce<ItemGroups>(
        (group, item, index) => {
          const { groupLabel } = item;
          if (groupLabel) {
            group[groupLabel] = group[groupLabel] ?? [];
            group[groupLabel].push(
              <ListDropdownItem
                key={item.id}
                item={item}
                index={index}
                handleClickItem={(ev) => handleClickItem(ev, item.id)}
              />
            );
          }

          return group;
        },
        {}
      );

      for (const group in groupedDropdownItems) {
        elements.push(
          <GroupLabel
            size={TextSize.XXS}
            weight={TextWeight.BOLD}
            color={Color.PRIMARY40}
            uppercase
          >
            {group}
          </GroupLabel>
        );
        elements.push(...groupedDropdownItems[group]);
      }

      return elements;
    } else {
      return getDropdownItems(filteredItems);
    }
  }, [handleClickItem, isGroupedItems, items, searchTerm, selectedItems]);

  return (
    <Wrapper>
      <InputTagWrapper ref={inputTagRef} focusBorder={focusBorder}>
        <BCScrollArea
          autoHeight
          ref={refScroll}
          autoHeightMax={94}
          size={ScrollAreaSize.S}
          autoHide
        >
          <TagsWrapper
            marginLeft={
              items && items.length !== selectedItems?.length ? '12px' : '0px'
            }
            onClick={() => {
              setOpenDropdown(false);
            }}
          >
            {getSelectedItems()}
            {allowAddingNewTags ||
            (items && items.length !== selectedItems?.length) ? (
              <Search
                value={searchTerm}
                maxLength={50}
                onChange={(val) => setSearchTerm(val)}
                onClick={onClickInput}
                onKeyDown={handleKeyDownInput}
                onFocus={() => setFocusBorder(true)}
                onBlur={onBlurInput}
                placeholder={placeholder}
                type="text"
              ></Search>
            ) : null}
          </TagsWrapper>
        </BCScrollArea>

        {items && items.length !== selectedItems?.length && openDropdown ? (
          <DropdownWrapper
            distance="4px"
            direction={FlexDirection.COLUMN}
            top={dropdownTop}
            borderBottom={!dropdownItems?.length}
          >
            <BCScrollArea
              autoHeight
              autoHeightMax={256}
              size={ScrollAreaSize.S}
              autoHide
            >
              {dropdownItems?.length ? (
                dropdownItems
              ) : (
                <NoFoundWrapper>
                  <BCText
                    size={TextSize.S}
                    color={Color.PRIMARY60}
                    weight={TextWeight.REGULAR}
                  >
                    {noResultsFoundText ||
                      `We couldn’t find any results for

                                        `}
                  </BCText>
                  <BCText size={TextSize.S} weight={TextWeight.MEDIUM}>
                    &nbsp;{`“${searchTerm}”`}
                  </BCText>
                </NoFoundWrapper>
              )}
            </BCScrollArea>
          </DropdownWrapper>
        ) : null}
      </InputTagWrapper>
    </Wrapper>
  );
}
