import { ReactElement } from 'react';
import styled from '@emotion/styled';
import { TextSize } from '../constants/TextSize';
import { Color } from '../constants/Color';
import { useTranslation } from 'react-i18next';

export interface BCSearchboxProps {
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  name?: string;
  icon?: string | ReactElement;
}

const BCSearchboxCon = styled.div`
  position: relative;
  z-index: 2;
`;

const BCSearchboxInput = styled.input`
  background: #fff;
  height: 40px;
  padding-left: 40px;
  max-width: 270px;
  font-size: ${TextSize.XS};
  border: 1px solid ${Color.SECONDARY_LIGHT};
`;

const BCSearchboxIcon = styled.span`
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 1;
  font-size: 15px;
`;

export const BCSearchbox = ({
  className,
  onChange,
  value,
  name,
  icon = 'fas fa-search',
}: BCSearchboxProps) => {
  const [t] = useTranslation();
  return (
    <BCSearchboxCon className={className}>
      <BCSearchboxInput
        type="search"
        name={name}
        className="hidden-xs"
        placeholder={t('pages.Search')}
        onChange={onChange}
        value={value}
      />
      <BCSearchboxIcon className="secondary-light hidden-xs">
        {typeof icon === 'string' ? <i className={icon} /> : icon}
      </BCSearchboxIcon>
    </BCSearchboxCon>
  );
};

export default BCSearchbox;
