import { ReactElement, useEffect, useState } from 'react';
import BCDropList, { BCDropListOptionsType } from '../dropList/bcdrop-list';
import Textbox from '../textbox/bctextbox';
import Searchbox from '../searchbox/bcsearchbox';
import { useForceUpdate } from '../hooks/useForceUpdate';
import { BCTooltip } from '../tooltip';

export const ColumnTypes = {
  Normal: 'N',
  DropList: 'D',
  Textbox: 'T',
  Status: 'S',
  Actions: 'A',
  Custom: 'C',
};

export const StatusTypes = {
  Active: 'A',
  Pending: 'P',
  Inactive: 'I',
};

export type Action = {
  key: string;
  icon: string | ReactElement;
  handler?: () => any;
  tooltip?: string;
};

export type Column = {
  title?: string;
  valueResolver?: (obj: Row) => string;
  valueSetter?: (obj: Row, newValue: string) => void;
  renderer?: (obj: Row) => JSX.Element;
  type?: string;
  list?: BCDropListOptionsType[];
  actions?: Action[] | ((row: any) => Action[]);
  sorted?: boolean;
  hiddenXsClass?: boolean;
};

export type Row = { [key: string]: unknown } & {
  id: any;
};

/* eslint-disable-next-line */
export type BCTableProps = {
  className?: string;
  rows: Row[];
  columns: Column[];
  dropListChanged?: (row: Row, choosedKey?: string) => void;
  textboxChanged?: (row: Row) => void;
  actionClicked?: (key: string, row: Row, index: number) => void;
  builtInSearch?: boolean;
  searchPattern?: string;
  noItemsMessage?: string;
  rtl?: boolean;
};

export const BCTable = ({
  className = '',
  rows: inputRows = [],
  columns = [],
  dropListChanged,
  textboxChanged,
  actionClicked,
  builtInSearch= false,
  searchPattern = '',
  noItemsMessage = 'No items found.',
  rtl,
}: BCTableProps) => {
  const [rows, setRows] = useState<Row[]>(inputRows);
  const [filterText, setFilterText] = useState<string | undefined>(
    searchPattern
  );
  const forceUpdate = useForceUpdate();

  if (searchPattern !== filterText) {
    setFilterText(searchPattern);
  }

  useEffect(() => {
    setRows(inputRows);
  }, [inputRows]);

  const resolveCell = (row: Row, column: Column, index: number) => {
    switch (column.type) {
      case ColumnTypes.Normal:
        if (column.valueResolver)
          return <span>{column.valueResolver(row)}</span>;
        break;
      case ColumnTypes.Custom:
        if (column.renderer) return column.renderer(row);
        break;
      case ColumnTypes.DropList:
        if (column.valueResolver)
          return (
            <BCDropList
              selected={column.valueResolver(row)}
              onSelectOption={(choosedKey) => {
                if (dropListChanged) dropListChanged(row, choosedKey);
              }}
              options={column.list || []}
              noEmpty={true}
            />
          );
        break;
      case ColumnTypes.Textbox:
        if (column.valueResolver) {
          return (
            <Textbox
              type="text"
              value={column.valueResolver(row)}
              onChange={(newValue) => {
                if (column.valueSetter) column.valueSetter(row, newValue);
                forceUpdate();
              }}
              onBlur={() => {
                if (textboxChanged) textboxChanged(row);
              }}
            />
          );
        }
        break;
      case ColumnTypes.Status:
        if (column.valueResolver) {
          const value = column.valueResolver(row);
          if (value === StatusTypes.Active) {
            return (
              <span className="green-circle">
                <span className="forTable">Active</span>
              </span>
            );
          } else if (value === StatusTypes.Pending) {
            return <span className="pending">Pending</span>;
          } else if (value === StatusTypes.Inactive) {
            return <span className="inactive">Inactive</span>;
          }
        }
        break;
      case ColumnTypes.Actions:
        if (column.actions) {
          const actions =
            column.actions instanceof Function
              ? column.actions(row)
              : column.actions;
          return (
            <span>
              {actions.map((m) => (
                <span
                  className="action-icon"
                  key={m.key}
                  onClick={() => {
                    if (actionClicked) actionClicked(m.key, row, index);
                  }}
                >
                  <BCTooltip title={m.tooltip}>
                    {typeof m.icon === 'string' ? (
                      <i className={m.icon}></i>
                    ) : (
                      m.icon
                    )}
                  </BCTooltip>
                </span>
              ))}
            </span>
          );
        }
        break;
      default:
        return null;
    }
    return null;
  };

  const searchingFor = (filterText?: string) => {
    return (x: Row) => {
      if (!filterText) return true;
      let result = false;
      columns.forEach((element) => {
        if (
          element.type === ColumnTypes.Normal ||
          element.type === ColumnTypes.Custom
        ) {
          if (
            element.valueResolver &&
            element
              .valueResolver(x)
              .toLowerCase()
              .includes(filterText.toLowerCase())
          ) {
            result = true;
            return;
          }
        }
      });
      return result;
    };
  };

  const compareBy = (col: Column) => {
    return function (a: Row, b: Row) {
      if (col.valueResolver && col.valueResolver) {
        if (col.valueResolver(a) < col.valueResolver(b)) return -1;
        if (col.valueResolver(a) > col.valueResolver(b)) return 1;
      }
      return 0;
    };
  };

  const reverse = (col: Column) => {
    return function (a: Row, b: Row) {
      if (col.valueResolver && col.valueResolver) {
        if (col.valueResolver(a) > col.valueResolver(b)) return -1;
        if (col.valueResolver(a) < col.valueResolver(b)) return 1;
      }
      return 0;
    };
  };

  const sortBy = (col: Column) => {
    if (rows !== undefined && rows.length > 0) {
      const arrayCopy = [...rows];
      if (col.type === ColumnTypes.Normal && !col.sorted) {
        arrayCopy.sort(compareBy(col));
        arrayCopy.sort(compareBy(col));
        col.sorted = true;
        setRows(arrayCopy);
      }
      if (col.type === ColumnTypes.Normal && col.sorted) {
        arrayCopy.sort(reverse(col));
        arrayCopy.sort(reverse(col));
        col.sorted = false;
        setRows(arrayCopy);
      }
    }
  };

  /*downloadExcel = () => {
    if (!sheetsLibLoaded) {
      this.loadLib();
      return;
    }
    let wb = window.XLSX.utils.table_to_book(this.refs.theTable, { sheet: "Report" });
    let wbout = window.XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
    let blob = new Blob([this.s2ab(wbout)], { type: "application/octet-stream" });
    let nowText = moment().tz("Europe/Istanbul").format('DD-MM-YYYY_HH_mm_ss');
    FileSaver.saveAs(blob, 'report_' + nowText + '.xlsx');
  }

  s2ab = (s) => {
    if (typeof ArrayBuffer !== 'undefined') {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    } else {
      let buf = new Array(s.length);
      for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
  }

  loadLib = () => {
    let s = document.createElement('script');
    //TODO: Should be changed
    s.src = 'assets/scripts/xlsx.full.min.js';
    s.onload = () => {
      sheetsLibLoaded = true;
      this.downloadExcel();
    };
    document.head.appendChild(s);
  }*/

  return (
    <div className={className + 'lighter small'}>
      {builtInSearch ? (
        <Searchbox
          className="float-right tableSearch hidden-xs"
          onChange={(event) => setFilterText(event.target.value)}
          value={filterText}
        />
      ) : null}
      {rows !== undefined && rows.length > 0 ? (
        <table className="table-style">
          <thead>
            <tr>
              {columns.map((col) =>
                col.type === ColumnTypes.Actions ? (
                  <th
                    className={col.hiddenXsClass ? 'hidden-xs' : ''}
                    key={'tbl-col-' + col.title}
                    style={rtl ? { textAlign: 'right' } : { textAlign: 'left' }}
                  >
                    {col.title}
                    {/*<a role="button" onClick={this.downloadExcel} className="hidden-xs">
                        <BCTooltip title="Download" className="excel-icon2">
                          <i className="excel-icon flow-excel" ></i>
                        </BCTooltip>
                </a>*/}
                  </th>
                ) : (
                  <th
                    className={col.hiddenXsClass ? 'hidden-xs' : ''}
                    key={'tbl-col-' + col.title}
                    onClick={() => sortBy(col)}
                    style={rtl ? { textAlign: 'right' } : { textAlign: 'left' }}
                  >
                    {col.title}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {rows.filter(searchingFor(filterText)).map((row, index) => (
              <tr key={'tbl-row-' + index}>
                {columns.map((col) => (
                  <td
                    key={'tbl-cell-' + col.title + '-' + row.id}
                    className={col.hiddenXsClass ? 'hidden-xs' : ''}
                    style={rtl ? { textAlign: 'right' } : { textAlign: 'left' }}
                  >
                    {resolveCell(row, col, index)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>
          <br /> {noItemsMessage}
        </p>
      )}
    </div>
  );
};

export default BCTable;
