import { ReactNode } from 'react';
import styled from 'styled-components';
import Color from '../constants/Color';
import { ButtonType } from '../button/types';
import { BCText } from '../text/bctext';
import TextSize from '../constants/TextSize';
import TextWeight from '../constants/TextWeight';

type ColorTheme = ButtonType.PRIMARY | ButtonType.SECONDARY;

export type RadioButtonProps = {
    name?: string;
    value: string | number;
    onChange?: (value: string | number) => void;
    checked: boolean;
    label?: string;
    colorTheme: ColorTheme;
    children?: ReactNode;
    disabled?: boolean;
    className?: string;
    size?: TextSize;
    weight?: TextWeight;
};
const { PRIMARY, SECONDARY, ACCENT10, SECONDARY40, PRIMARY40 } = Color;

const Container = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const inputSize = '14px';

const InputContainer = styled.div<{ checked: boolean; colorTheme: ColorTheme }>`
    display: inline-block;
    line-height: 1.44;
    position: relative;
    width: ${inputSize};
    height: ${inputSize};
    margin-right: 10px;
    input[type='radio'] {
        margin-top: 2px;
        visibility: hidden;
    }
    &::before {
        border: solid 2px ${({ colorTheme }) => (colorTheme === ButtonType.PRIMARY ? SECONDARY40 : PRIMARY40)};
        border-radius: 50%;
        content: '';
        height: ${inputSize};
        width: ${inputSize};
        position: absolute;
        left: 0;
        top: 0;
    }
    &:hover::before {
        background-color: transparent;
    }
    ${({ checked }) =>
        checked &&
        `&::after {
            background: ${PRIMARY40};
            border-radius: 50%;
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            left: calc(50% - 3px);
            top: calc(50% - 3px);
    }`}
`;

export const BCRadioButton = ({
    name,
    value,
    checked,
    onChange,
    label,
    colorTheme = ButtonType.PRIMARY,
    children,
    disabled,
    className,
    size = TextSize.S,
    weight = TextWeight.REGULAR,
}: RadioButtonProps) => {
    const onClick = () => !disabled && onChange && onChange(value);

    return (
        <Container onClick={onClick} className={className}>
            <InputContainer checked={checked} colorTheme={colorTheme}>
                <input type="radio" checked={checked} readOnly value={value} name={name} />
            </InputContainer>
            <BCText color={colorTheme === ButtonType.PRIMARY ? SECONDARY : PRIMARY} size={size} weight={weight}>
                {label}
            </BCText>
            {children}
        </Container>
    );
};
