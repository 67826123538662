
export enum HTMLButtonType {
    BUTTON = 'button',
    SUBMIT = 'submit',
}

export enum ButtonType {
    DARK = 'dark',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    GHOST = 'ghost',
    DANGER = 'danger',
    DANGER_LIGHT = 'dangerLight',
    GREEN = 'green',
    BURNING_ORANGE = 'burningOrange',
    COLD_FUSION = 'coldFusion',
    COLD_FUSION01 = 'coldFusion01',
    DAGOBAH_FOREST = 'dagobahForest',
    /* use invertColor for black */
    WHITE = 'white',
    BORDERED_WHITE = 'bordered_white',
}
