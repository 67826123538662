import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { ButtonSize } from '../constants';

export interface BCDropListOptionsType {
  icon?: string;
  text: string;
  styleClass?: string;
  value: string;
  note?: string;
}

export interface BCDropListProps {
  className?: string;
  selected?: string;
  options: BCDropListOptionsType[];
  onSelectOption: (value?: string) => void;
  noEmpty?: boolean;
  emptyText?: string;
  placeholder?: string;
  buttonSize?: ButtonSize;
  rtl?: boolean;
  icon?: React.ReactNode;
}

const Container = styled.div<{ rtl?: boolean }>`
  display: inline-block;
  position: relative;
  .dropIcon {
    position: absolute;
    top: 8px;
    ${({ rtl }) => (rtl ? 'left: 10px;' : 'right: 10px;')};
  }
`;
const DropDownContent = styled.div`
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.05);
  position: absolute;
  width: 100%;
  z-index: 5;
  top: 46px;
`;
const DropDownContent2 = styled.div`
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.05);
  max-height: 300px;
  overflow: auto;
`;
const DropButtonNote = styled.span`
  right: 50px;
  position: absolute;
  font-size: 12px;
`;
const DropDownItemNote = styled.span`
  float: right;
  font-size: 12px;
`;
const Icon = styled.i<{ rtl?: boolean }>`
  ${({ rtl }) => (rtl ? 'right: 0px;' : 'left: 0px;')};
  top: 15px;
`;

export const BCDropList = ({
  className = '',
  selected,
  options,
  onSelectOption,
  noEmpty = false,
  placeholder,
  emptyText,
  buttonSize = ButtonSize.M,
  rtl,
  icon,
}: BCDropListProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  let selectedText = '';
  let selectedStyle: string | undefined = '';
  let selectedIcon: string | undefined = '';
  let selectedNote: string | undefined = '';
  let selectedObj;
  if (options) {
    selectedObj = options.filter((m) => m.value === selected);
  }
  if (selectedObj && selectedObj.length > 0) {
    selectedText = selectedObj[0].text;
    selectedIcon = selectedObj[0].icon;
    selectedNote = selectedObj[0].note;
    selectedStyle = selectedObj[0].styleClass;
  }

  const showMenuHandler = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (showMenu) return closeMenuHandler();
      setShowMenu(true);
      setTimeout(
        () => document.addEventListener('click', closeMenuHandler),
        500
      );
    },
    [showMenu, setShowMenu]
  );

  const closeMenuHandler = useCallback(() => {
    setShowMenu(false);
    document.removeEventListener('click', closeMenuHandler);
  }, [setShowMenu]);

  return (
    <Container rtl={rtl} className={'dropdown ' + className}>
      <button
        className={
          `dropbtn ${buttonSize} ` +
          (selectedObj && selectedObj.length > 0 ? ' selected ' : '') +
          (selectedStyle ? selectedStyle : '')
        }
        onClick={showMenuHandler}
        type="button"
      >
        {selectedIcon ? (
          <Icon rtl={rtl} className={selectedIcon + ' dropbtn-icon'}></Icon>
        ) : icon && typeof icon === 'string' ? (
          <Icon className={icon}></Icon>
        ) : (
          <Icon className="dropbtn-icon">{icon}</Icon>
        )}

        {selectedText.length > 0 ? (
          <span className="translate">{selectedText}</span>
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
        <DropButtonNote>{selectedNote}</DropButtonNote>

        <span className="dropIcon">
          {showMenu ? (
            <ChevronUpIcon width={22} height={22} />
          ) : (
            <ChevronDownIcon width={22} height={22} />
          )}
        </span>
      </button>
      {showMenu ? (
        <DropDownContent className="dropdown-content">
          <DropDownContent2 className="dropdown-content2">
            {!noEmpty ? (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowMenu(false);
                  onSelectOption(undefined);
                }}
              >
                {emptyText || 'None'}
              </a>
            ) : null}
            {options &&
              options.map((op) => (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowMenu(false);
                    onSelectOption(op.value);
                  }}
                  key={op.value}
                  className={op.styleClass}
                >
                  {op.icon ? (
                    typeof op.icon === 'string' ? (
                      <Icon className={op.icon}></Icon>
                    ) : (
                      op.icon
                    )
                  ) : null}
                  {op.text}
                  <DropDownItemNote>{op.note}</DropDownItemNote>
                </a>
              ))}
          </DropDownContent2>
        </DropDownContent>
      ) : null}
    </Container>
  );
};

export default BCDropList;
